import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { GoLiveBanner, SummaryPanel, BillBreakup } from 'storybook-ui-components';

const BillSummary = ({ currency }) => {
  const {
    commitment,
    goliveMonth,
    currentMonthIsGoLiveMonth,
    newClient,
    billableDays,
    totalModuleCost,
    totalPlatformCost,
    effectiveCost,
    totalCost,
  } = useSelector((state) => state.pricing.billingSummary);
  const [openPopup, setOpenPopup] = useState(false);
  const currencySymbolMap = useSelector((state) => get(state, 's3Config.commonConfig.configData.currency', []));

  const handlePopupOpen = () => {
    setOpenPopup(true);
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  return (
    <div id="usage__bill">
      {currentMonthIsGoLiveMonth && (
      <GoLiveBanner
        commitment={commitment}
        goLive={goliveMonth}
        currentMonthGoLiveMonth={newClient}
        currency={currency}
        billableDays={billableDays}
        currencySymbolMap={currencySymbolMap}
      />
      )}
      <SummaryPanel
        heading="Total Payable Amount"
        currency={currency}
        amount={Math.max(totalCost, commitment).toLocaleString()}
        viewBreakup
        infoText="This is the total amount that is to be paid at the end of the month"
        onButtonClick={handlePopupOpen}
        currencySymbolMap={currencySymbolMap}
      />
      {openPopup && (
      <BillBreakup
        currency={currency}
        moduleCost={totalModuleCost.toLocaleString()}
        platformCost={totalPlatformCost.toLocaleString()}
        totalCost={totalCost.toLocaleString()}
        mmc={commitment.toLocaleString()}
        effectiveCost={effectiveCost.toLocaleString()}
        closePopup={handleClosePopup}
        currencySymbolMap={currencySymbolMap}
      />
      )}
    </div>
  );
};

BillSummary.propTypes = {
  currency: PropTypes.string.isRequired,
};

export default BillSummary;
