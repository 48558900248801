import React from 'react';
import PropTypes from 'prop-types';
import { ModuleBillingTable } from 'storybook-ui-components';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import Header from '../PricingInfo/Header';
import { usageDownloadType } from '../../../containers/Common/Download/utils';
import CustomButton from '../../Common/Buttons/CustomButton';
import DownloadIcon from './DownloadIcon';

function ModuleBilling({
  fetchLogs, isLoading, currency, appId,
}) {
  const currencySymbolMap = useSelector((state) => get(state, 's3Config.commonConfig.configData.currency', []));
  const { data, totalCost, appIdWiseUsageData } = useSelector((state) => state.pricing.moduleUsage);
  const tableData = appId === 'all' ? data : appIdWiseUsageData?.[appId]?.usageRows;
  const total = appId === 'all' ? totalCost : appIdWiseUsageData?.[appId]?.totalCost;

  const userAppIds = useSelector((state) => state.user.appIds);
  const appIdList = appId === 'all' ? Object.keys(userAppIds) : [appId];
  const appIdOptions = Object.keys(appIdWiseUsageData)
    ?.map((appIdValue) => ({ label: appIdValue, value: appIdValue }));
  appIdOptions.push({ label: 'All', value: 'all' });

  const fetchLogsForModuleBilling = () => fetchLogs(usageDownloadType);

  return (
    <>
      <div id="usage__header_filters">
        <Header headerText="Module Billing" />
        <CustomButton
          IconLeft={DownloadIcon}
          onClick={fetchLogsForModuleBilling}
        >
          Download Logs
        </CustomButton>
      </div>
      <ModuleBillingTable
        tableData={tableData}
        currency={currency}
        totalCost={total}
        appIdList={appIdList}
        isLoading={isLoading}
        credentialsType="STAGING & PRODUCTION"
        currencySymbolMap={currencySymbolMap}
      />
    </>
  );
}

ModuleBilling.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  fetchLogs: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
  appId: PropTypes.string.isRequired,
};

export default ModuleBilling;
