import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { BillingSummary } from 'storybook-ui-components';
import CommonSelect from '../../Common/Inputs/CommonSelect';

const UsageSummary = ({
  currency, appIdOptions, isLoading, selectedDate, handleAppIdChange, appId,
}) => {
  const {
    totalCost: moduleCost, appIdWiseUsageData: moduleAppIdWiseData,
  } = useSelector((state) => state.pricing.moduleUsage);
  const { totalCost: platformCost, appIdWiseUsageData: platformAppIdWiseData } = useSelector(
    (state) => state.pricing.transactionUsage,
  );
  const totalModuleCost = appId === 'all' ? moduleCost : get(moduleAppIdWiseData, [appId, 'totalCost']);
  const totalPlatformCost = appId === 'all' ? platformCost : get(platformAppIdWiseData, [appId, 'totalCost']);
  const currencySymbolMap = useSelector((state) => get(state, 's3Config.commonConfig.configData.currency', []));

  return (
    <div id="usage__summary">
      <h3>
        Usage :
        {' '}
        {selectedDate}
      </h3>
      <div id="usage__header_filters">
        <div className="usage_filters">
          <CommonSelect
            options={appIdOptions}
            label="Select AppId"
            handleChange={handleAppIdChange}
            disabled={isLoading}
            initValue="all"
            fixedLabel="AppIds: "
          />
        </div>
      </div>
      <BillingSummary
        total={(totalModuleCost + totalPlatformCost).toLocaleString()}
        moduleCost={totalModuleCost.toLocaleString()}
        platformCost={totalPlatformCost.toLocaleString()}
        currency={currency}
        currencySymbolMap={currencySymbolMap}
      />
    </div>
  );
};

UsageSummary.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  selectedDate: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
  appIdOptions: PropTypes.array.isRequired,
  handleAppIdChange: PropTypes.func.isRequired,
  appId: PropTypes.string.isRequired,
};

export default UsageSummary;
